<template>
  <div class="ood">
    <div class="container">
      <img src="@/assets/img/course/Class12/banner.jpg" alt="" />
      <img src="@/assets/img/course/Class12/1.jpg" alt="" />
      <img src="@/assets/img/course/Class12/2.jpg" alt="" />
      <img src="@/assets/img/course/Class12/3.jpg" alt="" />
      <img src="@/assets/img/course/Class12/4.jpg" alt="" />
      <div>
		  <router-link to="/course/11">
		  <img src="@/assets/img/course/Class12/5.jpg" alt="" />
		  </router-link>
	  </div>
      <img src="@/assets/img/course/Class12/6.jpg" alt="" />
    </div>
    <div class="prices">
      <span class="usdPrice">${{ courseCombosList[0].usdprice }} /</span>
      <span class="rmbPrice">￥{{ courseCombosList[0].price }}</span>
      <div class="buy" @click="buyNow(courseCombosList[0])">立即购买</div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getCourseDetail } from "@/service/course";
import { setStorageForArray } from "@/utils/index";
export default {
  name: "Class12",
  data() {
    return {
      courseCombosList: [],
    };
  },
  mounted() {
    getCourseDetail(12).then((res) => {
      this.courseCombosList = res.result.courseCombosList;
    });
  },
  methods: {
    buyNow(item) {
      setStorageForArray("order", [item]);
      this.$router.push("/pay/confirm");
    },
  },
};
</script>
<style scoped lang="scss">
@import url("../../assets/css/course.scss");
.ood {
  width: 100%;
  padding-top: 54px;
  .container {
    img {
      width: 100%;
      vertical-align: top;
    }
  }
}

</style>